textarea {
  font-family: 'Helvetica';
  font-size: 14px;
  height: 120px;
  padding-left: 4px;
  resize: none;
  width: 100%;
}

.detail-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.notes-title {
  margin-top: 30px;
  font-weight: 600;
  margin-bottom: 6px
}

.comments-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.textarea-comments {
  margin-bottom: 20px;
}

.comments-history-title {
  margin: 20px 0;
}

.comments-history {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.comments-history-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
}

.comments-history-list::after {
  content: "";
  border: 1px solid #cacaca;
  margin: 10px 0;
}

.comments-history-list:last-child::after {
  content: "";
  border: 0;
  margin: 0;
}

.date-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.comments-history-text {
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-top: 6px;
  padding: 6px;
}

.status-history-wrapper {
  max-width: 500px;
}

.status-history-wrapper::after {
  content: "";
  display: flex;
  flex-direction: column;
  border: 1px solid #cacaca;
  margin: 6px 0; 
}


@media (min-width: 768px) {
  textarea {
    width: 40%;
  }

  .detail-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .notes-title {
    margin-top: 0;
  }
}