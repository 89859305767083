.layout {
  background-color:  #F2F2F2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

div.faq-text {
  font-size: 14px;
  line-height: 1.4;
}
div.faq-text span {
  display: block;
}

div.faq-text strong {
  margin-bottom: .5em;
}

div.faq-text ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

div.faq-text li {
  margin-bottom: 1em;
}

.Mui-disabled {
  opacity: .4;
}

div.modal {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

div.modal.visible {
  display: flex;
}

div.modal > div {
  background: white;
  padding: 20px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px;
}

div.modal > div p {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 2em;
  margin-top: 0;
}

.MuiInputBase-inputMultiline {
width: 100% !important;
}
