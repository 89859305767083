.spn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, .1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #00372A;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-j5w0w9-MuiStepConnector-root {
  display: none;
}

@media (min-width: 768px) {
  .css-j5w0w9-MuiStepConnector-root {
    display: block;
  }
}