.complaints-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  border-bottom: 1px solid #cacaca;
  padding: 6px 0;
}

.complaint {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}

.complaints-list-desktop-header {
  display: none !important;
  padding: 20px 0;
}

@media (min-width: 768px) {
  .complaints-list-wrapper {
    flex-wrap: nowrap;
  }
  .complaint {
    align-items: center;
  }

  .complaint.column-ref { width: 20%; }
  .complaint.column-status { width: 40%; }
  .complaint.column-date { width: 20%; }
  .complaint.column-action { width: 20%; }

  .complaint-header {
    display: none;
  }

  .complaints-list-desktop-header {
    display: flex !important;
  }
}
