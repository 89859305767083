p {
  margin: 0
}

h2 {
  margin-top: 0;
}

.complaint-title {
  background-color: #00372A;
  color: white;
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0;
  padding-left: 6px;
}

.container {
  display: flex;
  flex-direction: column;
}

.date {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 30px;
}

.wrapper-date {
  display: flex;
  flex-direction: column;
  gap: 4px
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  border-bottom: 1px solid #cacaca;
  border-top: 1px solid #cacaca;
  padding: 6px 0;
}
.complaint {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.complaint-stats-grid {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: space-around;
  gap: 10px;
}
.complaint-stats-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 8px;
  gap: 1em;
  border: 1px solid #cacaca;
  width: 100%;
}
.complaints-list-desktop-header {
  display: none !important;
  padding: 20px 0;
}

.react-datepicker__input-container input {
  padding: 6px;
  border: 1px solid #00372A;
  max-width: 80px;
}

@media (min-width: 768px) {
  .wrapper {
    justify-content: space-between;
  }
  .complaint {
    align-items: center;
  }
  .status-label {
    text-align: center;
    width: 200px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .wrapper-date {
    width: 200px;
  }
  .complaint-stats-grid {
    flex-wrap: nowrap;
  }
  .complaint-stats-item {
    width: 16.66%;
    min-height: 4em;
  }
  .complaints-list-desktop-header {
    display: flex !important;
  }
  .react-datepicker__input-container input {
    max-width: 100%;
  }
}
